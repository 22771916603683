






























































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { Demand } from "@/store/models/DemandAndSupply";
import {
  CropVarietyStore,
  CropStore,
  DemandSupplyStore,
  MetaStore,
  CommentStore
} from "@/store/modules";
import { MetaInfo } from "vue-meta";
import { convertADtoBS, nepaliDate } from "@/store/modules/dateConverter";
import moment from "moment";
import { CropVarietyComment, CommentDetail } from "@/store/models/comment";

@Component({
  components: {},
  metaInfo(): MetaInfo {
    return {
      title: "Comment List",
      titleTemplate: "%s - SQCC",
      meta: [
        { charset: "utf-8" },
        { name: "keyword", content: "Sqcc comment list" },
        {
          name: "description",
          content: "List of Comments for admin",
          vmid: "demandList"
        },
        { name: "viewport", content: "width=device-width, initial-scale=1" }
      ]
    };
  }
})
export default class SCMList extends Vue {
  searchUser: string | null = null;
  cropId: number | null = null;
  cropVarietyId: number | null = null;
  seen: boolean | null = null;

  pageNum: number = 1;

  language: string = "";

  loading: boolean = false;
  dialogVisible: boolean = false;
  dialogEditVisible: boolean = false;

  comment_only: boolean = false;
  reply_only: boolean = false;
  query: any = {};

  currentEditComment: CropVarietyComment = {} as CropVarietyComment;

  get comment() {
    return CommentStore.CommentDetail;
  }

  get dataList() {
    return CommentStore.AllComments;
  }

  indexMethod(index: number) {
    let i = (this.dataList.page - 1) * this.dataList.page_size + index + 1;
    return i;
  }

  get crops() {
    return CropStore.CropNameList;
  }

  get cropVarieties() {
    let varieties = CropVarietyStore.CropVarieties;
    if (this.cropId && this.cropId !== null) {
      varieties = varieties.filter(x => x.croptypeid == this.cropId);
    }
    return varieties;
  }

  get seedTypes() {
    return DemandSupplyStore.SeedTypeList;
  }

  reply(commentid: number) {
    this.$validator.reset();
    this.$validator.errors.clear();
    this.currentEditComment = {
      parentid: commentid,
      cropvarietiesid: this.comment.cropvarietiesid
    } as CropVarietyComment;
    this.dialogEditVisible = true;
  }

  editReply(reply: CropVarietyComment) {
    this.$validator.reset();
    this.$validator.errors.clear();
    this.currentEditComment = reply;
    this.dialogEditVisible = true;
  }

  editComment(comment: CropVarietyComment) {
    this.$validator.reset();
    this.$validator.errors.clear();
    this.currentEditComment = comment as CropVarietyComment;
    this.dialogEditVisible = true;
  }

  removeComment(id: number) {
    this.$confirm("This will permanently delete this comment. Continue?", "Warning", {
      confirmButtonText: "Delete",
      confirmButtonClass: "el-button--danger",
      cancelButtonText: "Cancel",
      showClose: false,
      type: "warning"
    })

      .then(async () => {
        await CommentStore.deleteFromAllComments(id);
        if (this.comment.parentid != null) {
          await CommentStore.getCommentDetailById(this.comment.parentid);
        } else {
          await CommentStore.getAllCommentsOnly(this.query);
          this.dialogVisible = false;
        }
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "Delete cancelled"
        });
      });
  }

  async submitComment() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      await CommentStore.saveComment(this.currentEditComment);
      let data = this.dataList.results.find(x => x.id == this.currentEditComment.id);
      if (data) {
        data = this.currentEditComment;
      }
      this.currentEditComment = {} as CropVarietyComment;
      if (this.comment.parentid == null) {
        CommentStore.getCommentDetailById(this.comment.id);
      } else {
        CommentStore.getCommentDetailById(this.comment.parentid);
      }
      CommentStore.getAllCommentsOnly(this.query);
      this.dialogEditVisible = false;
    }
  }

  getMoment(date: string) {
    return moment(new Date(date)).fromNow();
  }

  changeCrop() {
    this.cropVarietyId = null;
  }

  thousandSeprator(amount: number) {
    if (amount == null) {
      return null;
    }
    amount = Number(amount);
    if (amount !== undefined || amount !== 0 || amount !== "0" || amount !== null) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return amount;
    }
  }

  search() {
    this.query = { page: 1 };
    this.pageNum = 1;
    if (this.cropId != null) {
      this.query["cropId"] = String(this.cropId);
    }
    if (this.cropVarietyId != null) {
      this.query["cropVarietyId"] = String(this.cropVarietyId);
    }
    if (this.seen != null) {
      this.query["seen"] = String(this.seen);
    }
    if (this.searchUser != null) {
      this.query["searchUser"] = String(this.searchUser);
    }
    this.routerReplacer();
    CommentStore.getAllCommentsOnly(this.query);
  }

  clearSearch() {
    this.query = { page: 1 };
    this.pageNum = 1;
    this.cropId = null;
    this.cropVarietyId = null;
    this.seen = null;
    this.searchUser = null;
    this.routerReplacer();
    CommentStore.getAllCommentsOnly(this.query);
  }

  routerReplacer(params?: { comment_only?: boolean; reply_only?: boolean }) {
    this.query = {};
    if (params) {
      if (params.comment_only) {
        this.query["comment_only"] = true;
        this.comment_only = true;
      } else if (params.reply_only) {
        this.query["reply_only"] = true;
        this.reply_only = true;
      }
    }
    if (this.pageNum) {
      this.query["page"] = String(this.pageNum);
    }
    if (this.comment_only) {
      this.query["comment_only"] = String(this.comment_only);
    }
    if (this.reply_only) {
      this.query["reply_only"] = String(this.reply_only);
    }
    if (this.cropId != null) {
      this.query["cropId"] = String(this.cropId);
    }
    if (this.cropVarietyId != null) {
      this.query["cropVarietyId"] = String(this.cropVarietyId);
    }
    if (this.seen != null) {
      this.query["seen"] = String(this.seen);
    }
    if (this.searchUser != null) {
      this.query["searchUser"] = String(this.searchUser);
    }
    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: this.query
        })
        .catch(e => {});
    }
    return this.query;
  }

  async handlePageChange(val: number) {
    this.pageNum = val;
    this.loading = true;
    this.query = this.routerReplacer();
    await CommentStore.getAllCommentsOnly(this.query);
    this.loading = false;
  }

  toogleExpand(row: any) {
    let $table = this.$refs.dataTable as any;
    $table.toggleRowExpansion(row);
  }

  async viewComment(row: any) {
    await CommentStore.changeToSeen({ commentId: row.id });
    row.seen = true;
    CommentStore.getCommentDetailById(row.id);
    this.dialogVisible = true;
    CommentStore.getCommentUnseenCount();
  }

  tableRowClassName(params: { row: any; rowIndex: number }) {
    if (!params.row.seen) {
      return "unseen-row";
    }
    return "";
  }

  private async created() {
    CropStore.getAllCropName();
    CropVarietyStore.getAllCropVariety();
    this.language = this.$i18n.locale;
    if (this.$route.query.page) {
      this.pageNum = Number(this.$route.query.page);
    }
    if (this.$route.query.comment_only) {
      this.comment_only = Boolean(this.$route.query.comment_only);
    }
    if (this.$route.query.reply_only) {
      this.reply_only = Boolean(this.$route.query.reply_only);
    }
    if (this.$route.query.cropId) {
      this.cropId = Number(this.$route.query.cropId);
    }
    if (this.$route.query.cropVarietyId) {
      this.cropVarietyId = Number(this.$route.query.cropVarietyId);
    }
    this.query = this.routerReplacer({
      comment_only: this.comment_only,
      reply_only: this.reply_only
    });
    this.loading = true;
    await CommentStore.getAllCommentsOnly(this.query);
    this.loading = false;
  }
}
