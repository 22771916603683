










































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { CommentStore } from "../../store/modules";

@Component({
  metaInfo: {
    title: "Admin",
    titleTemplate: "%s - SQCC",
    meta: [
      { charset: "utf-8" },
      { name: "description", content: "Admin Panel", vmid: "admin" },
      { name: "viewport", content: "width=device-width, initial-scale=1" }
    ]
  }
})
export default class Adminmenu extends Vue {
  get unseenStatus() {
    return CommentStore.UnseenStatus;
  }

  get isSqccChief() {
    return ["SQCCChief"].includes(String(localStorage.getItem("role")));
  }

  created() {
    CommentStore.getCommentUnseenCount();
  }
}
