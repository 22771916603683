


















































import { Component, Vue } from "vue-property-decorator";
import Normaluser from "@/components/UIComponents/Admin/Normaluser.vue";
import Catalogmanager from "@/components/UIComponents/Admin/Catalogmanager.vue";

@Component({
  components: {
    Catalogmanager,
    Normaluser
  }
})
export default class Adminmain extends Vue {
  data() {
    return {
      date: "",
      formInline: {
        user: "",
        region: "",
        lastname: "",
        email: ""
      }
    };
  }
  activeName: string = "first";
  drawer: boolean = false;
  direction: string = "btt";
  handleClick(tab: any, event: any) {
    console.log(tab, event);
  }
}
