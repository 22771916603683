













































































































































































import { Vue, Component } from "vue-property-decorator";
import {
  CropStore,
  LoadStore,
  DemandSupplyStore,
  CropVarietyStore,
  DSADStore
} from "@/store/modules";
import { AllocationData } from "@/store/models/dsad";
import { MetaInfo } from "vue-meta";

@Component({
  metaInfo(): MetaInfo {
    return {
      title: "Add Allocation",
      titleTemplate: "%s - SQCC",
      meta: [
        { charset: "utf-8" },
        { name: "keyword", content: "Sqcc allocation list" },
        {
          name: "description",
          content: "List of Allocation of seeds",
          vmid: "demandList"
        },
        { name: "viewport", content: "width=device-width, initial-scale=1" }
      ]
    };
  }
})
export default class Allocation extends Vue {
  $refs!: {
    allocationTable: InstanceType<typeof Vue>;
  };
  cropGroupId: number = 0;
  varietyId: number = 0;
  seedTypeId: number = 0;
  batchId: number = 0;
  saving: boolean = false;

  get dataList() {
    if (DSADStore.Allocation.data) {
      return DSADStore.Allocation.data;
    }
    return [];
  }

  get header() {
    if (DSADStore.Allocation.header) {
      return DSADStore.Allocation.header;
    }
    return [];
  }

  get allocationReport() {
    if (DSADStore.Allocation.allocationreport) {
      return DSADStore.Allocation.allocationreport;
    }
    return null;
  }
  get isSqccChief() {
    return ["SQCCChief"].includes(String(localStorage.getItem("role")));
  }

  get vhToPx() {
    return (vh: number) => {
      const vhPercent = vh / 100;
      return document.documentElement.clientHeight * vhPercent;
    };
  }

  get maxTableHeight() {
    return (vh: number) => {
      let px = this.vhToPx(vh);
      if (px < 500) {
        return 500;
      }
      return px;
    };
  }
  rerenderTable = 0;

  async scrollToTable() {
    this.$refs.allocationTable.$el.scrollIntoView();
  }

  calculateRemainingSupply(supply: any) {
    let remain: any = supply.seedquantity;
    if (this.dataList) {
      for (let data of this.dataList) {
        remain = remain - (data as any)[supply.prop].allocationquantity;
      }
      let total = this.header.filter(x => x.prop == supply.prop)[0];
      for (let data of this.dataList) {
        (data as any)[supply.prop].remainingSupply = remain;
        if (total && total.seedquantity) {
          (data as any)[supply.prop].total = total.seedquantity;
        }
      }
    }
    supply.remain = remain;
    return remain;
  }

  calculateRemainingDemand(demand: any) {
    let remain: any = demand.seedquantity;
    if (this.header) {
      for (let data of this.header) {
        remain = remain - demand[data.prop].allocationquantity;
      }
    }
    demand.remain = remain;
    return remain;
  }

  get totalSupply() {
    let total = 0;
    if (this.header) {
      for (let supply of this.header) {
        total += Number(supply.seedquantity);
      }
    }
    return total;
  }

  get totalDemand() {
    let total = 0;
    if (this.dataList) {
      for (let demand of this.dataList) {
        total += Number(demand.seedquantity);
      }
    }
    return total;
  }

  get remainingSupply() {
    let total = this.totalSupply;
    if (this.header && this.dataList) {
      for (let demand of this.dataList) {
        for (let supply of this.header) {
          total -= Number(demand[supply.prop].allocationquantity);
        }
      }
    }
    return total;
  }

  get remainingDemand() {
    let total = this.totalDemand;
    if (this.dataList) {
      for (let demand of this.dataList) {
        for (let supply of this.header) {
          total -= Number(demand[supply.prop].allocationquantity);
        }
      }
    }
    return total;
  }

  private get crop() {
    let crop = CropStore.CropNameList.filter(x => x.groupid == this.cropGroupId)[0];
    if (crop) {
      return crop;
    }
    return {};
  }

  private get seedType() {
    let seedType = DemandSupplyStore.SeedTypeList.filter(x => x.id == this.seedTypeId)[0];
    if (seedType) {
      return seedType;
    }
    return {};
  }

  private get variety() {
    let variety = CropVarietyStore.CropVarieties.filter(x => x.id == this.varietyId)[0];
    if (variety) {
      return variety;
    }
    return {};
  }

  saveAndSendEmail() {
    this.saveData(true);
  }

  saveAndForwardToChief() {
    this.saveData(false, true);
  }

  saveAndRevertToSaved() {
    this.saveData(false, false, true);
  }
  async saveData(send_mail = false, forward_to_chief = false, revert_to_saved = false) {
    let isValid: boolean = true;
    if (this.header) {
      if (this.header.length <= 0) {
        isValid = false;
        return this.$notify.error("No supplies are present");
      }
      for (let header of this.header) {
        if (header.remain && header.remain < 0) {
          isValid = false;
        }
      }
    }
    if (this.dataList.length <= 0) {
      isValid = false;
      return this.$notify.error("No demands are present");
    }
    for (let data of this.dataList) {
      if (data.remain && data.remain < 0) {
        isValid = false;
      }
    }
    if (isValid) {
      let message = "This will save the allocation in database. Continue?";
      if (forward_to_chief) {
        message =
          "This will save the allocation and forward this allocation to chief for approval. Continue?";
      }
      if (send_mail) {
        message =
          "This will save the allocation and send emails to all organizations. The allocation cannot be edited or removed. Continue?";
      }
      this.$confirm(message, "Warning", {
        confirmButtonText: "Submit",
        confirmButtonClass: "el-button--primary",
        cancelButtonText: "Cancel",
        showClose: false,
        type: "warning"
      })

        .then(async () => {
          this.saving = true;
          let data = {
            data: this.dataList,
            header: this.header,
            send_mail: send_mail,
            forward_to_chief: forward_to_chief,
            revert_to_saved: revert_to_saved
          };
          let error = false;
          await DSADStore.postAllocation(data).catch(error => {
            error = true;
          });
          this.saving = false;
          if (error) {
            this.$notify.error("Saving Failed");
          } else {
            this.$notify.success("Saved Successfully");
            this.calculateRemaining();
            let previousRouteName = this.$route.query.previousRouteName;
            if (previousRouteName) {
              previousRouteName = String(previousRouteName);
              this.$router.push({ name: previousRouteName }).catch(error => {});
            } else {
              this.$router.push({ name: "ChooseAllocation" }).catch(error => {});
            }
          }
        })

        .catch(() => {
          this.$message({
            type: "info",
            message: "Submit cancelled"
          });
        });
    }
  }

  async calculateRemaining() {
    for (let demand of this.dataList) {
      this.calculateRemainingDemand(demand);
    }
    for (let header of this.header) {
      this.calculateRemainingSupply(header);
    }
  }

  async created() {
    if (this.$route.query.cropGroupId) {
      this.cropGroupId = Number(this.$route.query.cropGroupId);
    }

    if (this.$route.query.varietyId) {
      this.varietyId = Number(this.$route.query.varietyId);
    }

    if (this.$route.query.seedTypeId) {
      this.seedTypeId = Number(this.$route.query.seedTypeId);
    }

    if (this.$route.query.batchId) {
      this.batchId = Number(this.$route.query.batchId);
    }

    CropStore.getDSADCropName({ phase: "allocation" });
    DemandSupplyStore.getAllSeedType();
    CropVarietyStore.getAllowedCropVarietiesByCropId(this.cropGroupId);
    await DSADStore.getAllocation({
      cropGroupId: this.cropGroupId,
      varietyId: this.varietyId,
      seedTypeId: this.seedTypeId,
      batchId: this.batchId
    });
    this.calculateRemaining();
  }
}
