











































































import { Button } from "element-ui";
import { Vue, Component } from "vue-property-decorator";
import Homeslider from "@/components/UIComponents/Homeslider.vue";
import { CropStore, LoadStore, DemandSupplyStore, CropVarietyStore } from "@/store/modules";
import Cropstat from "@/components/UIComponents/Cropstat.vue";
import Recentvarieties from "@/components/UIComponents/Recentvarieties.vue";
import Marketstat from "@/components/UIComponents/Marketstat.vue";

@Component({
  metaInfo: {
    title: "Choose Allocation",
    titleTemplate: "%s - SQCC",
    meta: [
      { charset: "utf-8" },
      { name: "keyword", content: "allocation,page" },
      {
        name: "description",
        content: "Allocation Selection Page",
        vmid: "allocation"
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" }
    ]
  }
})
export default class ChooseAllocation extends Vue {
  loading: boolean = true;

  allocation: any = {};

  private get crops() {
    return CropStore.CropNameList;
  }

  private get seedTypes() {
    return DemandSupplyStore.SeedTypeList;
  }

  private get varieties() {
    let varieties = CropVarietyStore.CropVarieties;
    return varieties;
  }

  async submit() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      let batchId = this.crops.filter(x => x.groupid == this.allocation.dsadgroupid)[0].batchid;
      if (batchId) {
        this.$router.push({
          name: "Allocation",
          query: {
            cropGroupId: this.allocation.dsadgroupid,
            varietyId: this.allocation.cropvarietyid,
            seedTypeId: this.allocation.seedtypeid,
            batchId: String(batchId)
          }
        }).catch(error => {});
      }
    }
  }

  private async created() {
    this.loading = true;
    await CropStore.getDSADCropName({ phase: "allocation" });
    await DemandSupplyStore.getAllSeedType();
    this.loading = false;
  }

  loadCropVariety(event: any) {
    this.allocation.cropvarietyid = null as any;
    let cropGroupId = this.crops.filter(x => x.groupid == event)[0].groupid;
    if (cropGroupId != undefined) {
      CropVarietyStore.getAllowedCropVarietiesByCropId(cropGroupId);
    }
  }
}
