import { render, staticRenderFns } from "./CommentList.vue?vue&type=template&id=3b86c5c9&scoped=true&"
import script from "./CommentList.vue?vue&type=script&lang=ts&"
export * from "./CommentList.vue?vue&type=script&lang=ts&"
import style0 from "./CommentList.vue?vue&type=style&index=0&id=3b86c5c9&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b86c5c9",
  null
  
)

export default component.exports